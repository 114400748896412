import React from "react"
import { graphql } from "gatsby"
import BaseLayout from "./baseLayout"
import { object, string } from "prop-types"
import { Newsbar } from "../components/Newsbar"
import "../styles/main.scss"
import getTemplateData from "../utilities/getTemplateData"

export const query = graphql`
  query ($slug: String!) {
    ecsPage(slug: { eq: $slug }) {
      content
      id
      slug
    }

    ecsSitemap(slug: { eq: $slug }) {
      id
      content {
        visibleInBreadcrumbs
        label {
          key
          value
        }
        link {
          type
          value
        }
      }
      parent {
        id
      }
    }

    allEcsSitemap {
      nodes {
        id
        content {
          label {
            key
            value
          }
          link {
            queryString {
              preserveQsData
            }
            type
            value
          }
          visibleInBreadcrumbs
        }
        slug
        parent {
          id
        }
      }
    }

    ecsSitewideMessages {
      content {
        messageType
        siteMessageEnabled
        siteMessages {
          messageLocation
          shortCopy
        }
      }
    }

    allNewsJson {
      edges {
        node {
          id
          date
          href
          snippet
        }
      }
    }

    allDownloadsJson {
      edges {
        node {
          id
          title
          href
        }
      }
    }
  }
`

const ArticleRightRailPage = ({ data, location }) => {
  const { headData, components, sitewideMessages, globalHeader } =
    getTemplateData(data, location)
  return (
    <>
      <BaseLayout
        head={headData}
        disclaimers={components["Disclaimer"]}
        sitewideMessages={sitewideMessages}
        globalHeader={globalHeader}
      >
        {components["Carousel"] && (
          <div className="featured-area">
            <div className="article-hero">{components["Carousel"]}</div>
          </div>
        )}
        <Newsbar>
          {components["stockticker"]}
          {components["highlight"]}
          {components["downloads"]}
          {components["news"]}
        </Newsbar>
        <section>{components["valueDrivers"]}</section>
        {components["Dynamic_Cta"]}
        <section id="content-link" role="main" tabIndex="-1">
          <div
            className={`ls-area article ${
              components["Right_Rail"] ? "has-rightrail" : ""
            }`}
            id="content-body"
          >
            {components["Link_List"] && (
              <div className="tds-row">{components["Link_List"]}</div>
            )}
            {components["Body_Content"]}
            {components["Page_Promo"]}
          </div>
          {components["Right_Rail"]}
        </section>
        {components["Footer_Cap"]}
      </BaseLayout>
    </>
  )
}

export default ArticleRightRailPage

ArticleRightRailPage.propTypes = {
  data: object,
  location: string,
}
